// Core
import React, { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Context
import { AuthContext } from 'contexts/AuthContext';

// Utils
import { reachDataLayer, reachGoal, reachParams } from 'utils';
import {
	accId,
	EVENTS,
	pageUrl,
	paramName,
	paramSuccess,
	userId,
} from 'utils/reachParams';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';

// Styles
import styles from './Feedback.module.css';

const Feedback = ({ uploaded }) => {
	const { push } = useHistory();
	const { user, accountId } = useContext(AuthContext);

	const clickHandler = () => {
		reachGoal(EVENTS.goToMain);
		const eventName = EVENTS.goToMain;
		const formName = 'Кнопка "Вернуться на главную" – после загрузки каталога';
		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: accountId,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
		push('/');
	};

	return (
		<div className={styles.centeredContainer}>
			<div className={styles.centeredContainer__title}>
				<Label
					text={<FormattedMessage id="rod.repertoire.uploaded.title" />}
					font="--gilroy-Medium-40"
					black
				/>
			</div>
			<div className={styles.centeredContainer__content}>
				<Label
					text={<FormattedHTMLMessage id={'rod.repertoire.uploaded.content'} />}
					font="--gilroy-Medium-18-140"
					black
				/>
			</div>
			<Button
				text={<FormattedMessage id={'rod.action.to_index_page'} />}
				variant={'primary'}
				onClick={clickHandler}
			/>
		</div>
	);
};

export default Feedback;
