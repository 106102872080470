// Core
import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PoseGroup } from 'react-pose';
import { compose } from 'recompose';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { reachDataLayer, reachGoal, reachParams } from 'utils';
import { accId, EVENTS, pageUrl, paramName, paramSuccess, userId } from 'utils/reachParams';

// Constants
import ReleaseCompletionSteps from 'material-design/constants/releaseCompletionSteps';

// UI
import { withAuth, withUI } from 'hocs';
import { Col, Container, Row } from 'components/Grid';
import BaseModal from './BaseModal';
import AddReleaseModal from 'containers/Modal/AddReleaseModal/AddReleaseModal';
import { Modal, Shade } from './Posed/Posed';

// Icons
import nota from 'images/nota_white.svg';
import release from 'images/release_disc.svg';
import releaseDisabled from 'images/release_disabled.svg';
import dynamic from 'images/dynamic_white.svg';
import catalog from 'images/catalog.svg';
import close from 'images/close.svg';

// Styles
import s from './LoadPage.module.css';

const LoadPage = (props) => {
	const { active, setActive } = props;

	const {
		user,
		accountId,
		isAuth,
		isAddReleaseEnableForAccount,
		isAddCompositionEnableForAccount,
	} = useContext(AuthContext);

	const { getAccountPersonalData, getAccountContactsData } = useContext(
		RootContext
	);

	const {
		setIsTransferRelease,
		setCompletionStep,
		notTransferReleaseFlag,
		setReloadWhenCreateRelease,
		setReloadWhenCreateComposition,
		upload,
		isCompositionCreateDisabled,
		isReleaseCreateDisabled,
		isExpiredContract,
	} = useContext(UIContext);

	const [modal, setModal] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	let { push } = useHistory();

	useEffect(() => {
		if (isAuth && accountId !== 'null') {
			getAccountPersonalData(accountId)
				.then((account) => {
					account = account.data.data;
				})
				.catch((err) => push('/accounts'));

			getAccountContactsData(accountId)
				.then((res) => {
					res = res.data.data;
				})
				.catch((err) => push('/accounts'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsVisible(active);
	}, [active]);

	const releaseGeneralHandler = (isTransfer) => {
		setModal(false);
		localStorage.removeItem('releaseId');
		isTransfer
			? localStorage.setItem('isTransferRelease', true)
			: localStorage.removeItem('isTransferRelease');
		setIsTransferRelease(isTransfer);
		setCompletionStep(ReleaseCompletionSteps.RELEASE);

		if (
			window.location.href.includes('/release/create') ||
			window.location.href.includes('/file') ||
			window.location.href.includes('/tracks') ||
			window.location.href.includes('/authors') ||
			(window.location.href.includes('/release') &&
				window.location.href.includes('/lyrics')) ||
			window.location.href.includes('/cover') ||
			window.location.href.includes('/distribution') ||
			window.location.href.includes('/confirm')
		) {
			setReloadWhenCreateRelease(true);
		} else {
			setReloadWhenCreateRelease(false);
		}
		push('/release/create');
	};

	const createCompositionHandler = () => {
		reachGoal('rod_click_add_repertory');
		const eventName = EVENTS.addComposition;
		const formName = 'Кнопка "Добавить произведение"';
		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: accountId,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
		createNewAssetHandler('composition');
	};

	const compositionGeneralHandler = () => {
		localStorage.removeItem('compositionId');

		if (
			window.location.href.includes('/compositions/create') ||
			window.location.href.includes('/authors_shares') ||
			(window.location.href.includes('/compositions') &&
				window.location.href.includes('/lyrics')) ||
			window.location.href.includes('/composition_recordings') ||
			window.location.href.includes('/review')
		) {
			setReloadWhenCreateComposition(true);
		} else {
			setReloadWhenCreateComposition(false);
		}
		push('/compositions/create');
	};

	const createNewAssetHandler = (item) => {
		if (item === 'release') {
			releaseGeneralHandler(false);
		}

		if (item === 'composition') {
			compositionGeneralHandler();
		}

		if (item === 'catalogue') {
			push(`/repertoire/${accountId}/upload`);
		}

		willUnmount();
	};

	const transferReleaseHandler = () => {
		sendGoalAndParams();
		releaseGeneralHandler(true);
		willUnmount();
	};

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			setActive(false);
		}, 300);
	};

	const sendGoalAndParams = () => {
		reachGoal(EVENTS.chooseReleaseType);
		const eventName = EVENTS.chooseReleaseType;
		const formName = 'Выбор типа релиза';
		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: accountId,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
	};

	const addReleaseHandler = () => {
		reachGoal(EVENTS.addRelease);
		!notTransferReleaseFlag ? setModal(true) : createNewAssetHandler('release');

		const eventName = EVENTS.addRelease;
		const formName = 'Кнопка "Добавить релиз"';

		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: accountId,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
	};

	return (
		<>
			<BaseModal className={s.Modal}>
				{modal && (
					<AddReleaseModal
						title={<FormattedMessage id={'rod.btn.add_release'} />}
						subtitle={
							<FormattedMessage
								id={
									'rod.release.create.step.distributions.description.transfer'
								}
							/>
						}
						newReleaseButtonLabel={<FormattedMessage id={'rod.new_release'} />}
						transferReleaseButtonLabel={
							<FormattedMessage id={'rod.transfer_release'} />
						}
						newReleaseButtonHandler={() => {
							sendGoalAndParams();
							createNewAssetHandler('release');
						}}
						transferReleaseButtonHandler={transferReleaseHandler}
						onClose={() => setModal(false)}
					/>
				)}
				<PoseGroup>
					{isVisible && [
						<Shade key="shade" className={s.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										md={12 - 3}
										onClick={(e) => {
											willUnmount(e);
										}}
									/>
									<Col md={3} className={s.LeftSide}>
										<div className={`${s.backdrop} ${s.active} ${s.container}`}>
											<button
												type="button"
												className={s.close__btn}
												onClick={() => willUnmount()}
												style={{ cursor: 'pointer' }}
											>
												<img src={close} alt="" />
											</button>
											<div className={s.btn__container}>
												{(isReleaseCreateDisabled &&
													!isAddReleaseEnableForAccount) ||
												isExpiredContract ? (
													<button type="button" className={s.btn__disabled}>
														<img
															className={s.img__svg}
															src={releaseDisabled}
															alt=""
														/>
														<p className={s.text}>
															<FormattedMessage id={'rod.btn.add_release'} />
														</p>
													</button>
												) : (
													<button
														type="button"
														className={s.btn__add}
														onClick={addReleaseHandler}
													>
														<img className={s.img__svg} src={release} alt="" />
														<p className={s.text}>
															<FormattedMessage id={'rod.btn.add_release'} />
														</p>
													</button>
												)}

												<button
													type="button"
													className={
														process.env.REACT_APP_CLIENT === 'mts'
															? s.btn__disabled
															: s.btn__add
													}
													disabled={process.env.REACT_APP_CLIENT === 'mts'}
													onClick={() => createNewAssetHandler('catalogue')}
												>
													<img
														className={s.img__svg}
														style={
															process.env.REACT_APP_CLIENT === 'mts'
																? { opacity: '0.5' }
																: {}
														}
														src={catalog}
														alt=""
													/>
													<p className={s.text}>
														<FormattedMessage id={'rod.btn.add_catalog'} />
													</p>
												</button>

												{isCompositionCreateDisabled &&
												!isAddCompositionEnableForAccount ? (
													<button type="button" className={s.btn__disabled}>
														<img
															className={s.img__svg}
															style={{ opacity: '0.5' }}
															src={nota}
															alt=""
														/>
														<p className={s.text}>
															<FormattedMessage
																id={'rod.btn.add_composition'}
															/>
														</p>
													</button>
												) : (
													<button
														type="button"
														className={s.btn__add}
														style={{ textAlign: 'left' }}
														onClick={createCompositionHandler}
													>
														<img className={s.img__svg} src={nota} alt="" />
														<p className={s.text}>
															<FormattedMessage
																id={'rod.btn.add_composition'}
															/>
														</p>
													</button>
												)}

												{upload.recordings && (
													<button type="button" className={s.btn__add}>
														<img className={s.img__svg} src={dynamic} alt="" />
														<p className={s.text}>
															<FormattedMessage id={'rod.btn.add_recordings'} />
														</p>
													</button>
												)}
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		</>
	);
};
export default compose(withAuth, withUI)(LoadPage);
