export const reachParams = (params) => {
	if (window.ym) {
		window.ym(92300155, 'params', params);
	} else {
		console.error('Yandex Metrica is not initialized');
	}
};

export const pageUrl = window.location.href;
export const paramName = 'Статус';
export const paramSuccess = 'успешно'
export const userId = 'Идентификатор пользователя';
export const accId = 'Идентификатор аккаунта';

export const EVENTS = {
	login: 'rod_click_login',
	recoveryPasswordSave: 'rod_click_recovery_password_save',
	requestPersonalForm: 'rod_submit_request_personal_form',
	registration: 'rod_click_registration',
	submitRequestSms: 'rod_submit_request_sms',
	submitRequestEmail: 'rod_submit_request_email',
	createNewAccount: 'rod_click_create_new_account',
	chooseClientType: 'rod_choose_client_type',
	chooseContentRights: 'rod_choose_content_rights',
	fillPersonalData: 'rod_fill_personal_data',
	fillContactData: 'rod_fill_contact_data',
	downloadDocuments: 'rod_download_documents',
	fillIds: 'rod_fill_ids',
	fillBankData: 'rod_fill_bank_data',
	submitRequest: 'rod_submit_request',
	submitAgreementParams: 'rod_submit_agreement_params',
	goToSignDocs: 'rod_click_go_to_sign_docs',
	signDocsSubmitSms: 'rod_sign_docs_submit_sms',
	signDocsSubmitManual: 'rod_sign_docs_submit_manual',
	addRelease: 'rod_click_add_release',
	chooseReleaseType: 'rod_choose_release_type',
	addComposition: 'rod_click_add_repertory',
	submitCatalog: 'rod_submit_repertory',
	goToMain: 'rod_click_go_to_main',
};
