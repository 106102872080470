// Core
import React, { PureComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

// Utils
import update from 'immutability-helper';
import { getHelpMessage } from '../../../../../messages/helper';
import { compose } from 'recompose';
import { generateIdentifiersSteps } from '../utils/generateIdentifiersSteps';
import { reachGoal } from 'utils';
import { EVENTS } from 'utils/reachParams';

// UI
import { Loading } from 'components';
import { Button, InfoButton } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { AutosuggestInput, DateInput, Input } from 'components/Inputs';
import { Label } from 'components/Labels/Label';
import { Wizard } from 'components/Wizard';
import accountTypes from 'constants/accountTypes';
import { withAuth, withLang, withRoot, withUI } from 'hocs';
import removeImg from 'images/close_button.svg';
import plusImg from 'images/plus.svg';

// Styles
import styles from 'pages/account/Create/steps/06-Identifiers/Identifiers.module.css';


class Identifiers extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);
		let title = '';
		switch (props.personalData.business_type) {
			case accountTypes.PERSONAL:
				title = `${props.personalData.last_name} ${
					props.personalData.first_name
				} ${
					props.personalData.patronymic ? props.personalData.patronymic : ''
				}`;
				break;
			case accountTypes.PERSONAL_ENTREPRENEUR:
			case accountTypes.COMPANY:
				title = props.personalData.title;
				break;
			default:
				title = '';
		}
		this.state = {
			initialSocietiesIDs: [],
			societies: [{}],
			errFromInput: false,
			pseudonyms: [
				{
					id: null,
					category: 'pseudonym',
				},
			],
			newPseudonyms: [],
			isShowSocieties: true,
			isShowIds: true,
			loading: true,
			errors: {},
			removeDisabled: false,
		};
		this.steps = generateIdentifiersSteps(props.personalData.business_type);
	}

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.isPseudonymsUpdateNeeded !==
				this.props.isPseudonymsUpdateNeeded &&
			this.props.isPseudonymsUpdateNeeded === true
		) {
			this.pseudonymsUpdate();
		}

		if (
			this.state.societies?.length < prevState.societies?.length ||
			(this.props.errors &&
				Object.keys(this.props.errors).length > 0 &&
				Object.keys(prevProps.errors).length !==
					Object.keys(this.props.errors).length)
		) {
			this.getInputErrors();
		}

		if (prevProps.id !== this.props.id) {
			this.update();
		}

		if (
			prevProps.personalData.business_type !==
			this.props.personalData.business_type
		) {
			this.steps = generateIdentifiersSteps(
				this.props.personalData.business_type
			);
		}
	}

	makeDataCorrect = (societies, suggestData) => {
		let result;
		result = societies.map((element) => {
			element['mebership_since'] = element.mebership_since.substring(0, 10);
			const currentSociety = suggestData.find((suggest) => {
				return suggest.id === element.community_account_id;
			});
			element['title_ru'] = currentSociety ? currentSociety.title_ru : '';
			return element;
		});
		return result;
	};

	update() {
		const {
			authContext: { changeWidget, setIsAccountLoading },
			rootContext: {
				getAccountPseudonyms,
				suggestSociety,
				getAccountPseudonymsData,
			},
		} = this.props;

		setIsAccountLoading(true);
		changeWidget();
		suggestSociety()
			.then((suggestSocietyData) => {
				getAccountPseudonyms(this.props.id)
					.then((res) => {
						res = res.data.data;
						const societiesWithCorrectedData = this.makeDataCorrect(
							res.societies,
							suggestSocietyData
						);
						this.setState(
							update(this.state, {
								initialSocietiesIDs: {
									$set: res.societies.map((element) => element.id),
								},
								societies:
									res.societies.length > 0
										? {
												$set: societiesWithCorrectedData,
										  }
										: { $set: this.state.societies },
							})
						);
					})
					.then(() => {
						getAccountPseudonymsData(this.props.id).then((pseudonymsData) => {
							pseudonymsData = pseudonymsData.data.data;
							this.setState(
								update(this.state, {
									pseudonyms:
										pseudonymsData.length > 0
											? { $set: pseudonymsData }
											: { $set: this.state.pseudonyms },
									loading: { $set: false },
								})
							);
							setIsAccountLoading(false);
						});
					});
			})
			.catch((error) => {
				console.error('Identifier - getAccountPseudonyms error: ', error);
				console.error(
					'Identifier - getAccountPseudonyms error.response: ',
					error.response
				);
				setIsAccountLoading(false);
			});
	}

	pseudonymsUpdate() {
		const {
			rootContext: { getAccountPseudonymsData },
		} = this.props;
		let newPseudonyms = this.state.pseudonyms.filter((item) => !item.id);
		let updatedPseudonyms = [...this.state.pseudonyms];

		this.setState({ newPseudonyms });
		getAccountPseudonymsData(this.props.id).then((newPseudonymsData) => {
			newPseudonymsData = newPseudonymsData.data.data;
			updatedPseudonyms = updatedPseudonyms.map((pseudonym) => {
				if (
					pseudonym.id &&
					newPseudonymsData.find((item) => item.id === pseudonym.id)
				) {
					return pseudonym;
				}
				const equalPseudonymWithId = newPseudonymsData.find(
					(dataItem) =>
						(pseudonym.title === dataItem.title &&
							pseudonym.isni &&
							dataItem.isni &&
							pseudonym.isni === dataItem.isni &&
							pseudonym.ipi_name_number &&
							dataItem.ipi_name_number &&
							pseudonym.ipi_name_number === dataItem.ipi_name_number) ||
						(pseudonym.title === dataItem.title &&
							!pseudonym.isni &&
							!dataItem.isni &&
							!pseudonym.ipi_name_number &&
							!dataItem.ipi_name_number) ||
						(pseudonym.title === dataItem.title &&
							!pseudonym.isni &&
							!dataItem.isni &&
							pseudonym.ipi_name_number &&
							dataItem.ipi_name_number &&
							pseudonym.ipi_name_number === dataItem.ipi_name_number) ||
						(pseudonym.title === dataItem.title &&
							pseudonym.isni &&
							dataItem.isni &&
							!pseudonym.ipi_name_number &&
							!dataItem.ipi_name_number &&
							pseudonym.isni === dataItem.isni)
				);
				if (!pseudonym.id && equalPseudonymWithId) {
					pseudonym.id = equalPseudonymWithId.id;
					return pseudonym;
				}
				return pseudonym;
			});
			this.setState(
				update(this.state, {
					pseudonyms:
						updatedPseudonyms.length > 0
							? { $set: updatedPseudonyms }
							: { $set: newPseudonyms },
					loading: { $set: false },
				})
			);
		});
	}

	toggleSocieties = () => {
		this.setState({ isShowSocieties: !this.state.isShowSocieties });
	};
	toggleIds = () => {
		this.setState({ isShowIds: !this.state.isShowIds });
	};

	addSocietyRow = () => {
		this.setState(
			update(this.state, {
				societies: {
					$push: [
						{
							id: null,
						},
					],
				},
			})
		);
	};

	updateSocietyRow = (index, field) => (value) => {
		this.setState(
			update(this.state, {
				societies: {
					[index]: {
						[field]: {
							$set: value,
						},
					},
				},
			})
		);
	};

	updateSocietyName = (index) => (value) => {
		const mebership_since = this.state.societies[index].mebership_since
			? this.state.societies[index].mebership_since
			: null;
		if (mebership_since) {
			value['mebership_since'] = mebership_since;
		}
		this.setState(
			update(this.state, {
				societies: {
					[index]: { $set: value },
				},
			})
		);
	};

	removeSocietyRow = (index) => () => {
		this.setState(
			update(this.state, {
				societies: {
					$splice: [[index, 1]],
				},
			})
		);
	};

	addIdentifierRow = () => {
		this.setState(
			update(this.state, {
				pseudonyms: {
					$push: [{ id: null, category: 'pseudonym' }],
				},
				errors: {},
			})
		);
		this.setState({ errors: {} });
	};

	updateIdentifierRow = (index, field) => (value) => {
		const { errors } = this.state;

		this.setState(
			update(this.state, {
				pseudonyms: {
					[index]: {
						[field]: {
							$set: value,
						},
					},
				},
			})
		);

		if (value.length > 255) {
			this.setState({
				errors: {
					...errors,
					[index]: {
						...errors[index],
						max_length: true,
					},
				},
			});
		} else {
			this.setState({
				errors: {
					...errors,
					[index]: {
						...errors[index],
						max_length: false,
					},
				},
			});
		}
	};

	removeIdentifierRow = (index) => () => {
		const {
			rootContext: { deleteAccountPseudonym },
		} = this.props;

		if (this.state.pseudonyms[index].id) {
			this.setState({ removeDisabled: true });
			deleteAccountPseudonym(this.props.id, this.state.pseudonyms[index].id)
				.then(() => {
					this.setState(
						update(this.state, {
							pseudonyms: {
								$splice: [[index, 1]],
							},
						})
					);
				})
				.catch((error) => {
					console.error('Identifier - deleteAccountPseudonym error: ', error);
					console.error(
						'Identifier - deleteAccountPseudonym error.response: ',
						error.response
					);
					this.setState({
						errors: error.response?.data,
					});
				})
				.finally(() => {
					this.setState({ removeDisabled: false });
				});
		} else {
			this.setState(
				update(this.state, {
					pseudonyms: {
						$splice: [[index, 1]],
					},
				})
			);
		}
	};

	getInputErrors = () => {
		let societiesInputErrors = {};
		let pseudonymsInputErrors = {};
		let societiesLength = this.state.societies.length;

		if (this.state.isShowSocieties) {
			for (let i = 0; i < societiesLength; i++) {
				if (
					!this.state.societies[i].id ||
					!this.state.societies[i].mebership_since
				) {
					if (!this.state.societies[i].id) {
						societiesInputErrors[`societies.${i}.id`] = [{ rule: 'required' }];
					}
					if (!this.state.societies[i].mebership_since) {
						societiesInputErrors[`societies.${i}.mebership_since`] = [
							{ rule: 'required' },
						];
					}
				} else {
					societiesInputErrors = {};
					break;
				}
			}
		}

		switch (this.state.isShowIds) {
			case true:
				let pseudonymsLength = this.state.pseudonyms.length;
				for (let i = 0; i < pseudonymsLength; i++) {
					if (this.state.pseudonyms[i].title) {
						pseudonymsInputErrors = {};
						break;
					} else {
						pseudonymsInputErrors[`pseudonyms.${i}.title`] = [
							{ rule: 'required' },
						];
					}
					if (
						this.state.pseudonyms[i].ipi_name_number &&
						this.state.pseudonyms[i].ipi_name_number.length !== 11
					) {
						pseudonymsInputErrors[`pseudonyms.${i}.ipi_name_number`] = [
							{ rule: 'required' },
						];
					}
					if (
						this.state.pseudonyms[i].isni &&
						this.state.pseudonyms[i].isni.length !== 19
					) {
						pseudonymsInputErrors[`pseudonyms.${i}.isni`] = [
							{ rule: 'required' },
						];
					}
				}
				break;
			case false:
				pseudonymsInputErrors = {};
				break;
			default:
				break;
		}

		const inputErrors = { ...societiesInputErrors, ...pseudonymsInputErrors };
		this.setState({ errors: inputErrors });
		return inputErrors;
	};

	getValidRecordsFromSocieties = () => {
		let result = this.state.isShowSocieties
			? this.state.societies.map((society) => {
					if (society.id && society.mebership_since) {
						const { title_ru, title_en, ...rest } = society;
						return rest;
					} else return {};
			  })
			: [];
		if (result.length) {
			result = result.filter((society) => Object.keys(society).length !== 0);
		}
		return result;
	};

	getValidRecordsFromPseudonyms = () => {
		let result = this.state.isShowIds
			? this.state.pseudonyms.map((pseudonym) => {
					if (pseudonym.title) {
						return pseudonym;
					} else return {};
			  })
			: [];
		if (result.length) {
			result = result.filter(
				(pseudonym) => Object.keys(pseudonym).length !== 0
			);
		}
		return result;
	};

	nextHandler = () => {
		let inputErrors = {};

		reachGoal(EVENTS.fillIds);
		inputErrors =
			this.state.isShowSocieties && this.state.isShowIds
				? this.getInputErrors()
				: {};

		if (Object.keys(inputErrors).length) return false;

		if (typeof this.props.onChange === 'function') {
			this.props.onChange({
				societies: this.getValidRecordsFromSocieties(),
				pseudonyms: this.state.isShowIds
					? this.getValidRecordsFromPseudonyms()
					: [],
			});
		}
	};

	renderSocieties() {
		const {
			UIContext: { showModal },
		} = this.props;

		const { isShowSocieties, societies } = this.state;
		const lang = localStorage.getItem('lang');
		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'identifiers_societies')
		);

		return (
			<Row>
				<Col sm={10} offset={{ sm: 1 }}>
					<div className={styles.HeaderLine}>
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.societies.header'}
									/>
								}
								className={styles.Header}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
						<Checkbox
							text={<FormattedMessage id={'rod.field.skip_step'} />}
							className={styles.Checkbox}
							checked={!isShowSocieties}
							onChange={this.toggleSocieties}
						/>
					</div>
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.societies.sub_header'}
							/>
						}
						font="--gilroy-Medium-24"
						black
					/>
					{isShowSocieties && societies && (
						<div>
							{societies.map((society, index) => {
								return (
									<div key={index} className={styles.FormLine}>
										<FormattedMessage id={'rod.field.society'}>
											{(placeholder) => (
												<AutosuggestInput
													className={styles.Input}
													placeholder={placeholder}
													required
													value={{
														...society,
														title:
															lang === 'ru'
																? society.title_ru
																: society.title_en,
													}}
													errors={this.state.errors[`societies.${index}.id`]}
													onChange={this.updateSocietyName(index)}
												/>
											)}
										</FormattedMessage>
										<FormattedMessage id={'rod.field.from_date'}>
											{(placeholder) => (
												<DateInput
													placeholder={placeholder}
													value={society.mebership_since}
													errors={
														this.state.errors[
															`societies.${index}.mebership_since`
														]
													}
													onChange={this.updateSocietyRow(
														index,
														'mebership_since'
													)}
													lang={lang}
												/>
											)}
										</FormattedMessage>
										{index === 0 && (
											<Button icon onClick={this.addSocietyRow}>
												<img src={plusImg} alt="add" />
											</Button>
										)}
										{index > 0 && (
											<Button icon onClick={this.removeSocietyRow(index)}>
												<img src={removeImg} alt="remove" />
											</Button>
										)}
									</div>
								);
							})}
						</div>
					)}
				</Col>
			</Row>
		);
	}

	renderIds() {
		const {
			UIContext: { showModal },
		} = this.props;

		const lang = localStorage.getItem('lang');
		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'identifiers_names')
		);

		const { isShowIds, pseudonyms, errors, removeDisabled } = this.state;

		return (
			<Row className={styles.Section}>
				<Col md={10} offset={{ sm: 1 }}>
					<div className={styles.HeaderLine}>
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.identifiers.header'}
									/>
								}
								className={styles.Header}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
						<Checkbox
							text={<FormattedMessage id={'rod.field.skip_step'} />}
							className={styles.Checkbox}
							checked={!isShowIds}
							onChange={this.toggleIds}
						/>
					</div>

					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.identifiers.sub_header'}
							/>
						}
						font="--gilroy-Medium-24"
						black
					/>

					{isShowIds && (
						<div>
							<div className={styles.SubHeader}>
								{this.props.personalData?.business_type === 'individual' ? (
									<Label
										text={
											<FormattedMessage
												id={'rod.account.create.identifiers.pseudonyms.header'}
											/>
										}
										font="--gilroy-Medium-24"
										black
									/>
								) : (
									<InfoButton
										onClick={showModal({
											text: (
												<FormattedHTMLMessage
													id={
														'rod.account.create.identifiers.pseudonyms.header.help'
													}
												/>
											),
										})}
									>
										<Label
											text={
												<FormattedMessage
													id={
														'rod.account.create.identifiers.pseudonyms.header'
													}
												/>
											}
											font="--gilroy-Medium-24"
											black
										/>
									</InfoButton>
								)}
							</div>
							{pseudonyms
								.sort((a, b) => b.main - a.main)
								.map((pseudonym, index) => (
									<div key={index} className={styles.FormLine}>
										<FormattedMessage id={'rod.field.pseudonym'}>
											{(placeholder) => (
												<Input
													placeholder={placeholder}
													value={pseudonym.title}
													required
													errors={
														this.props.errors[`pseudonyms${index}`]?.title
													}
													onChange={this.updateIdentifierRow(index, 'title')}
													disabled={
														pseudonym.main === 1 &&
														this.props.personalData.business_type !==
															'individual'
													}
													className={
														pseudonym.main === 1 &&
														this.props.personalData.business_type !==
															'individual'
															? styles.disabled
															: ''
													}
												/>
											)}
										</FormattedMessage>
										{errors[index]?.max_length && (
											<span className={styles.error}>
												<FormattedMessage id="rod.field.pseudonym.error" />
											</span>
										)}
										<FormattedMessage id={'rod.field.ipi'}>
											{(placeholder) => (
												<Input
													placeholder={placeholder}
													value={pseudonym.ipi_name_number}
													errors={
														this.props.errors[`pseudonyms${index}`]
															?.ipi_name_number
													}
													onChange={this.updateIdentifierRow(
														index,
														'ipi_name_number'
													)}
												/>
											)}
										</FormattedMessage>
										<FormattedMessage id={'rod.field.isni'}>
											{(placeholder) => (
												<Input
													placeholder={placeholder}
													value={pseudonym.isni}
													errors={this.props.errors[`pseudonyms${index}`]?.isni}
													onChange={this.updateIdentifierRow(index, 'isni')}
												/>
											)}
										</FormattedMessage>
										{index === 0 && (
											<Button icon onClick={this.addIdentifierRow}>
												<img src={plusImg} alt="add" />
											</Button>
										)}
										{index > 0 && (
											<Button
												icon
												onClick={this.removeIdentifierRow(index)}
												className={removeDisabled ? styles.removeDisabled : ''}
												disabled={removeDisabled}
											>
												<img src={removeImg} alt="remove" />
											</Button>
										)}
									</div>
								))}
						</div>
					)}
				</Col>
			</Row>
		);
	}

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<div className={styles.Page}>
				<Wizard
					steps={this.steps}
					className={styles.Wizard}
					handleOpenPrevTabs={this.props.handleOpenPrevTabs}
				/>
				<div className={styles.Container}>
					{this.renderSocieties()}
					{this.renderIds()}
					{this.renderControls()}
				</div>
			</div>
		);
	}

	renderControls() {
		return (
			<Row>
				<Col md={5} offset={{ sm: 1 }}>
					<div className={styles.Controls}>
						<Button
							text={<FormattedMessage id={'rod.action.next'} />}
							variant={'primary'}
							onClick={this.nextHandler}
						/>
					</div>
					{this.state.errors.status === 'current_alias' && (
						<span className={styles.helper}>
							<FormattedMessage id={'rod.pseudonyms.main.forbid.delete'} />
						</span>
					)}
				</Col>
			</Row>
		);
	}
}

export default compose(withRoot, withAuth, withLang, withUI)(Identifiers);
