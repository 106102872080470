// Core
import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Context
import { UIContext } from 'contexts/UIContext';
import { AuthContext } from 'contexts/AuthContext';

// Constants
import ModalTypes from 'constants/modalTypes';

// Utils
import { correctDateInTracks } from './utils/correctDateInTracks';
import { toGeneralFormat } from './utils/toGeneralFormat';
import { accounts } from 'services';
import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';
import cloneDeep from 'lodash/cloneDeep';

// UI
import { InfoButton } from 'components/Buttons';
import SideTable from './SideTable/SideTable';
import { LyricsForm } from './LyricsForm';
import { BottomNavi } from 'components';

// Styles
import s from './Lyrics.module.css';

const Lyrics = (props) => {
	const [toogleMenu, setToogleMenu] = useState('');
	const [initialPersonal, setInitialPersonal] = useState([]);
	const [personal, setPersonal] = useState([]);
	const [selectPerson, setSelectPerson] = useState({});
	const [languages, setLanguages] = useState([]);
	const [isReqLive] = useState(false);
	const [errors, setErrors] = useState({});
	const [isLoadingLyrics, setIsLoadingLyrics] = useState(true);
	const [audio] = useState(new Audio());
	const [disableBtn, setDisableBtn] = useState(false);
	const [disableSaveBtn, setDisableSaveBtn] = useState(false);
	const [unsavedChangesModalShowed, setUnsavedChangesModalShowed] = useState(
		false
	);

	const {
		showModal,
		setIsBottomNaviShow,
	} = useContext(UIContext);

	const history = useHistory();
	const { accountId } = useContext(AuthContext);
	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;
	const MAX_LYRICS_LENGTH = 10000;

	const handleForm = async (index) => {
		toogleMenu === index ? setToogleMenu('') : setToogleMenu(index);

		try {
			await accounts.getReleaseData(
				h11EditId && isH11Edit ? h11EditId : releaseId
			);

			const donePersonal = cloneDeep(personal);
			setInitialPersonal(donePersonal);
		} catch (error) {
			console.error('An error occurred:', error);
		}
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getReleaseData(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then((res) => {
				const tracks = correctDateInTracks(res.data.data.tracks);
				setPersonal(tracks);
				if (Object.keys(selectPerson).length === 0) {
					setSelectPerson(tracks[0]);
				}
				let first = true;
				for (let i = 0; i < tracks.length; i++) {
					tracks[i].language = tracks[i].language
						? tracks[i].language
						: res.data.data.language || null;
					tracks[i].parental_warning_type = tracks[i].parental_warning_type
						? tracks[i].parental_warning_type
						: res.data.data.parental_warning_type;
					tracks[i].lyrics = tracks[i].lyrics
						? tracks[i].lyrics
						: res.data.data.lyrics;

					if (isValid(tracks[i])) {
						tracks[i].done = true;
					} else if (first) {
						setToogleMenu(i);
						first = false;
					}

					const initialTracks = cloneDeep(tracks);
					setInitialPersonal(initialTracks);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			});

		const languagesCache = lscache.get('languagesCache');
		if (languagesCache && !isReqLive) {
			setLanguages(languagesCache);
		} else {
			accounts
				.getLanguages()
				.then((res) => {
					res = res.data.data;
					setCache('languages', 'languagesCache', res);
					setLanguages(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (toogleMenu === '') {
			return;
		}

		if (personal[toogleMenu]?.lyrics_id?.url) {
			getLyricTextFromFile(personal[toogleMenu].lyrics_id.url);
		} else {
			setSelectPerson(personal[toogleMenu]);
			setIsLoadingLyrics(false);
		}

		setErrors({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal, toogleMenu]);

	const handleInstrumental = (value) => {
		personal[toogleMenu].is_instrumental = value;
		setPersonal([...personal]);
	};

	const getLyricTextFromFile = (url) => {
		fetch(url)
			.then((res) => res.text())
			.then((text) => {
				if (!text.includes('AccessDenied')) {
					let processedText = text
						.replace(/\n/g, '<br />')
						.replace(
							/^(<p>(<br\s*\/?>)*<\/p>)+|(<p>(<br\s*\/?>)*<\/p>)+$/g,
							''
						);

					personal[toogleMenu].lyrics = processedText;
					setSelectPerson(personal[toogleMenu]);
				} else {
					console.error('Access Denied');
				}
			})
			.catch((err) => {
				setSelectPerson(personal[toogleMenu]);
				console.error('Error:', err);
			})
			.finally(() => setIsLoadingLyrics(false));
	};

	const changeField = (field) => (e) => {
		setErrors({});
		switch (field) {
			case 'language':
				selectPerson[field] = e.id;
				break;
			case 'parental_warning_type':
				selectPerson[field] = e.code;
				break;

			default:
				selectPerson[field] = e.target.value;
				break;
		}
	};

	const isValid = (toCheck, showErr = false) => {
		let errorCount = 0;
		const newError = {};

		if (!toCheck?.is_instrumental) {
			if (toCheck.lyrics && +toCheck.lyrics.length > MAX_LYRICS_LENGTH) {
				newError.lyrics = 'max_length';
				errorCount++;
			}

			if (typeof toCheck.language !== 'number') {
				newError.language = 'обязательное поле';
				errorCount++;
			}

			if (!toCheck.parental_warning_type) {
				newError.parental_warning_type = 'обязательное поле';
				errorCount++;
			}
		}

		if (!errorCount) {
			setErrors({});
			return true;
		} else {
			if (showErr) {
				setErrors(newError);
			}
			return false;
		}
	};

	const recordingSave = () => {
		if (selectPerson?.is_instrumental) {
			selectPerson.language = null;
			selectPerson.parental_warning_type = null;
			selectPerson.lyrics = null;
			selectPerson.lyrics_id = null;
		}

		if (!isValid(selectPerson, true)) return;

		setDisableSaveBtn(true);
		setUnsavedChangesModalShowed(false);

		const clone = cloneDeep(selectPerson);
		clone.account_id = accountId;
		if (clone.lyrics) {
			clone.lyrics = clone.lyrics
				.replace(/\n/g, '')
				.replace(/^(<p>(<br\s*\/?>)*<\/p>)+|(<p>(<br\s*\/?>)*<\/p>)+$/g, '');

			if (clone.lyrics === '<p><br></p>') {
				clone.lyrics = '';
			}
		}

		setDisableBtn(true);
		accounts
			.addReleaseLyrics(
				h11EditId && isH11Edit ? h11EditId : releaseId,
				selectPerson.id,
				{
					parental_warning_type: clone.parental_warning_type,
					language: clone.language,
					lyrics: clone.lyrics,
					is_instrumental: clone.is_instrumental,
				}
			)
			.then((res) => {
				res = res.data.data;
				if (res.isrc) {
					const data = res.isrc.replace(
						/(\d{2})\.(\d{3})\.(\d{2})\.(\d{5})/,
						'$1-$2-$3-$4'
					);
					selectPerson.isrc = data;
				}
				if (res?.lyrics_id?.url) {
					selectPerson.lyrics_id = {
						...selectPerson.lyrics_id,
						url: res.lyrics_id.url,
					};
				}
				selectPerson.done = true;
				personal[toogleMenu] = selectPerson;
				setPersonal(personal);
				const savedPersonal = cloneDeep(personal);
				setInitialPersonal(savedPersonal);

				if (personal[toogleMenu + 1]) {
					if (isValid(personal[toogleMenu + 1])) {
						setToogleMenu('');
					} else {
						setToogleMenu(toogleMenu + 1);
					}
				} else {
					setToogleMenu('');
				}
				setDisableSaveBtn(false);
			})
			.then(() => {
				const ellementToScroll = document.getElementById('general');
				if (ellementToScroll) {
					ellementToScroll.scrollIntoView({
						behavior: 'smooth',
						block: 'end',
						inline: 'nearest',
					});
				}
			})
			.catch((err) => {
				console.error('Error', err.response?.data?.errors);
				console.error('Error', err.response?.data);
				setErrors(err.response?.data?.errors);
				setDisableSaveBtn(false);
			})
			.finally(() => setDisableBtn(false));
	};

	const nextHandler = () => {
		const formattedPersonal = toGeneralFormat(personal);
		const formattedInitialPersonal = toGeneralFormat(initialPersonal);
		const isChanged =
			JSON.stringify(formattedPersonal) !==
			JSON.stringify(formattedInitialPersonal);

		if (isChanged && !unsavedChangesModalShowed) {
			setUnsavedChangesModalShowed(true);
			showModal(
				{
					text: <FormattedMessage id={'rod.modal.unsaved_changes_info.text'} />,
					withClose: true,
					confirmBtnTxt: <FormattedMessage id={'rod.modal.understood'} />,
				},
				ModalTypes.FULL_MODAL
			)();
		} else {
			setDisableBtn(true);
			if (typeof props.onChange === 'function') {
				setPersonal(personal);
				audio.currentTime = 0;
				audio.pause();
				props
					.onChange(personal)
					.then()
					.catch()
					.finally(() => setDisableBtn(false));
			}
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/tracks`);
			} else {
				history.push(`/release/${releaseId}/authors`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const disabledNextBtn = () => {
		if (!personal.every((item) => item.done) || personal.length === 0)
			return true;

		return false;
	};

	return (
		<>
			<div className={s.page}>
				<div
					className={
						toogleMenu !== '' ? s.main__wrapper : s.main__wrapper_whithoutForm
					}
				>
					<div
						className={
							toogleMenu !== '' ? s.main : `${s.main} ${s.main__notForm}`
						}
					>
						<InfoButton
							className={`${s.info} ${s.title__info}`}
							onClick={showModal({
								text: (
									<FormattedHTMLMessage
										id={'rod.release.create.step.tracks.title.help'}
									/>
								),
							})}
						>
							<span className={s.title}>
								<FormattedHTMLMessage id={'rod.release.create.step.lyrics'} />
							</span>
						</InfoButton>
						<SideTable
							personal={personal}
							toogleMenu={toogleMenu}
							handleForm={handleForm}
							disableSaveBtn={disableSaveBtn}
						/>
					</div>

					{toogleMenu !== '' && !isLoadingLyrics && (
						<LyricsForm
							errors={errors}
							changeField={changeField}
							handleForm={handleForm}
							toogleMenu={toogleMenu}
							selectPerson={selectPerson}
							languages={languages}
							recordingSave={recordingSave}
							handleInstrumental={handleInstrumental}
							disableSaveBtn={disableSaveBtn}
						/>
					)}
				</div>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn() || disableBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
};

export default Lyrics;
