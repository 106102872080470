// Core
import { FormattedMessage } from 'react-intl';

// Styles
import s from '../File.module.css';

const ErrorsBlock = ({
	errors,
	checkUploadCount,
	getTracksCountError,
	releaseType,
	errorsValue,
	propsErrors,
	formatError,
}) => {
	const isH11Edit = window.location.href.includes('/edit/');

	return (
		<>
			{Object.keys(errors).includes('upload') && (
				<span className={s.ErrorHelper}>
					{checkUploadCount(errors.upload[0].rule, releaseType, errorsValue)}
				</span>
			)}
			{Object.keys(errors).includes('tracksCount') && (
				<span className={s.ErrorHelper}>
					{getTracksCountError(errors.tracksCount[0].max)}
				</span>
			)}
			{Object.keys(propsErrors).length > 0 &&
				Object.keys(propsErrors).includes('file') && (
					<span className={s.ErrorHelper}>
						{getTracksCountError(propsErrors?.file[0]?.max)}
					</span>
				)}
			{isH11Edit && Object.keys(formatError).length > 0 && (
				<span className={s.ErrorHelper}>
					<FormattedMessage id={'rod.field.update.error.file'} />
				</span>
			)}
		</>
	);
};

export default ErrorsBlock;
