// Core
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Contexts
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { reachDataLayer, reachGoal } from 'utils';
import {
	accId,
	EVENTS,
	pageUrl,
	paramName,
	paramSuccess,
	reachParams,
	userId,
} from 'utils/reachParams';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

// Data
import { acceptedFormats } from './utils/acceptedFormats';

// UI
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';
import { FormInput } from 'components/Form/FormInput';
import Help from './Help/Help';
import UploadedFile from './UploadedFile/UploadedFile';
import DashboardTextEditor from './TextEditor/TextEditor';
import { BottomNavi } from 'components';

// Styles
import styles from './Dashboard.module.css';

const Dashboard = (props) => {
	const { accountId, user } = useContext(AuthContext);
	const {
		getAccountPersonalData,
		getAccountContactsData,
		uploadRepertoire,
		isDashboardScrolled,
	} = useContext(RootContext);
	const { showUploadHandler, hideUploadHandler } = useContext(UIContext);
	const history = useHistory();

	const [catalogData, setCatalogData] = useState({ type: 'new', text: '' });
	const [maxCountError, setMaxCountError] = useState(false);
	const [repFiles, setRepFiles] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isBulletShow, setIsBulletShow] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [typeOfBullet, setTypeOfBullet] = useState('');
	const [countFilesError, setCountFilesError] = useState(false);
	const [errorFromBack, setErrorFromBack] = useState({});
	const [maxCountTimer, setMaxCountTimer] = useState(null);

	let { params } = useRouteMatch();
	let { push } = useHistory();
	const formElement = useRef(null);
	const maxCharsCount = 3000;
	const maxFilesCount = 20;

	const handleScroll = () => {
		const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

		clientHeight + Math.ceil(scrollTop) >= scrollHeight
			? setTypeOfBullet('up')
			: setTypeOfBullet('down');
	};

	useEffect(() => {
		const { clientWidth } = document.documentElement;
		const pageHeight = document.documentElement.clientHeight;
		const elementHeight = formElement.current?.clientHeight;
		if (formElement.current && elementHeight > pageHeight) {
			setIsBulletShow(true);
		}

		if (clientWidth > 1023) {
			isDashboardScrolled ? setTypeOfBullet('up') : setTypeOfBullet('down');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getAccountPersonalData(params.id)
			.then((account) => {
				account = account.data.data;
				if (
					account.completion_step !== accountCompletionSteps.PERSONAL_AREA &&
					account.completion_step !== accountCompletionSteps.REPERTOIRE_APPLY
				) {
					push(`/accounts/${params.id}`);
					hideUploadHandler();
				} else {
					showUploadHandler();
				}
			})
			.catch((err) => {
				console.error(
					'Dashboard - error.response - getAccountPersonalData: ',
					err.response
				);
				console.error('Dashboard - error - getAccountPersonalData: ', err);
				push('/accounts');
			});

		getAccountContactsData(params.id)
			.then((res) => {
				res = res.data.data;
			})
			.catch((err) => push('/accounts'));
		window.addEventListener('scroll', handleScroll);

		return () => {
			hideUploadHandler();
			window.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const upload = () => {
		reachGoal(EVENTS.submitCatalog);
		const eventName = EVENTS.submitCatalog;
		const formName = 'Кнопка "Отправить" – при загрузке каталога';

		const fd = new FormData();
		fd.append('text', catalogData.text);
		fd.append('type', catalogData.type);

		repFiles.forEach((file, index) => {
			fd.append(`file[${index}]`, file);
		});

		setIsUploading(true);

		uploadRepertoire(params.id, fd)
			.then((res) => {
				res = res.data.data;
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: paramSuccess,
								[userId]: user.id,
								[accId]: accountId,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
				setIsUploading(false);
				setRepFiles([]);
				history.push(`/repertoire/${accountId}/upload/success`);
			})
			.catch((err) => {
				setIsUploading(false);
				setErrorFromBack(err.response.data.errors);

				if (
					Object.keys(err.response.data.errors).length > 0 &&
					!Object.keys(err.response.data.errors).includes('text')
				) {
					setRepFiles([]);
				}

				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[paramName]: err.response.status,
								[userId]: user.id,
								[accId]: accountId,
							},
						},
					},
				};
				reachParams(yaParams);
			});
	};

	const handleFileUpload = (files) => {
		setCountFilesError(false);

		if (files.length === 0) {
			const error = new Error('No file was uploaded.');
			console.error('Documents - uploadAccountDocument error: ', error);
			return Promise.reject(error);
		}

		if (
			files.length > maxFilesCount ||
			repFiles.length + files.length > maxFilesCount
		) {
			const error = new Error(
				`The maximum number of files to upload is ${maxFilesCount}.`
			);
			console.error('Documents - uploadAccountDocument error: ', error);
			setCountFilesError(true);
			return;
		}

		for (const file of files) {
			const fileExtension = `.${file.name.split('.').pop()}`;
			if (!acceptedFormats.includes(fileExtension.toLowerCase())) {
				const error = new Error(`Unsupported file format: ${fileExtension}.`);
				console.error('Documents - uploadAccountDocument error: ', error);
				return Promise.reject(error);
			}
			file.id = uuidv4();
		}

		const newFiles = [...repFiles, ...files];
		setRepFiles(newFiles);
		return Promise.resolve();
	};

	const handleFileRemove = (id) => {
		const updatedFiles = repFiles.filter((file) => file.id !== id);
		setRepFiles(updatedFiles);

		if (updatedFiles.length <= maxFilesCount) {
			setCountFilesError(false);
		}
	};

	const setText = (e) => {
		setErrorFromBack({});

		if (maxCountTimer) {
			clearTimeout(maxCountTimer);
		}

		if (e.length > maxCharsCount) {
			setMaxCountError(true);

			const timer = setTimeout(() => {
				setMaxCountError(false);
			}, 3000);

			setMaxCountTimer(timer);
		} else {
			setMaxCountError(false);
			catalogData.text = e ?? '';
		}

		setCatalogData({ ...catalogData });
	};

	const setType = (field) => (value) => {
		catalogData[field] = value;
		setCatalogData({ ...catalogData });
	};

	return (
		<>
			<Row style={{ minHeight: '91%' }} nogutter nowrap className={styles.Row}>
				<>
					<Col className={styles.Left}>
						<div className={styles.Form} ref={formElement}>
							<Label font="--gilroy-Medium-32" black>
								<FormattedMessage id={`rod.field.upload.title`} />
							</Label>
							<div className={styles.FormLine}>
								<div className={styles.Subheader}>
									<Label
										text={
											<FormattedHTMLMessage
												id={
													process.env.REACT_APP_CLIENT === 'dgtal'
														? 'rod.repertoire.welcome.content.part.dgtal'
														: 'rod.repertoire.welcome.content.part'
												}
											/>
										}
										font="--gilroy-Medium-18"
										black
									/>
								</div>
								<Label
									font="--gilroy-Medium-24"
									className={styles.typesTitle}
									black
								>
									<FormattedMessage id={'rod.field.upload.catalog.type'} />
								</Label>
								<div className={styles.radio}>
									<FormInput
										type={'radio'}
										className={styles.radioGroup}
										name={'type'}
										errors={''}
										onChange={setType}
										data={catalogData}
										items={[
											{
												text: (
													<FormattedMessage
														id={'rod.field.upload.catalog.type.new'}
													/>
												),
												value: 'new',
											},
											{
												text: (
													<FormattedMessage
														id={'rod.field.upload.catalog.type.update'}
													/>
												),
												value: 'change',
											},
											{
												text: (
													<FormattedMessage
														id={'rod.field.upload.catalog.type.remove'}
													/>
												),
												value: 'takedown',
											},
										]}
									/>
								</div>
								<Label
									font="--gilroy-Medium-24"
									className={styles.filesTitle}
									black
								>
									<FormattedMessage id={`rod.field.upload.files.title`} />
								</Label>

								<DropZone
									onUpload={handleFileUpload}
									files={repFiles}
									accept=".zip,.xlsx,.xls,.xlsb,.txt,.doc,.docx,.pdf,.csv,.cr,.cwr,.v21,.v22"
									dropzoneId={'rod.field.upload.zip'}
									maxSize={1024 * 1024 * 100}
									kb
								/>
								{((repFiles && repFiles.length > 0) || countFilesError) && (
									<div className={styles.filesBlock}>
										{repFiles.map((file) => (
											<UploadedFile
												key={file.id}
												file={file}
												handleFileRemove={() => handleFileRemove(file.id)}
											/>
										))}
										{countFilesError && (
											<span className={styles.helper}>
												<FormattedMessage
													id="rod.multiple.upload.max_count"
													values={{
														value: maxFilesCount,
													}}
												/>
											</span>
										)}
									</div>
								)}

								<DashboardTextEditor
									setText={setText}
									errorFromBack={errorFromBack}
									maxCountError={maxCountError}
									maxCharsCount={maxCharsCount}
								/>
							</div>
						</div>
					</Col>
					<Help />
				</>
			</Row>
			<BottomNavi
				disabled={!repFiles.length || isUploading}
				next={upload}
				nextText="rod.action.send"
			/>
		</>
	);
};

Dashboard.propTypes = {};
export default Dashboard;
