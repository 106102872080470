// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import cloneDeep from 'lodash/cloneDeep';

// Constants
import ModalTypes from 'constants/modalTypes';

// UI
import AuthorForm from './AuthorForm/AuthorForm';
import { BottomNavi, Loading } from 'components';
import SideTable from './SideTable/SideTable';

// Hooks
import { withUI, withRoot } from 'hocs';

// Services & Utils
import { accounts } from 'services';
import changeField from 'material-design/helper/helper';
import { toGeneralFormat } from './utils/toGeneralFormat';

// Styles
import s from './Authors.module.css';
import { UIContext } from 'contexts/UIContext';

const Authors = (props) => {
	const { setContributor, showModal, setIsBottomNaviShow } = useContext(
		UIContext
	);

	const [loading, setLoading] = useState(false);
	const [lastSavePerson, setLastSavePerson] = useState({});
	const [initialPersonal, setInitialPersonal] = useState([]);
	const [personal, setPersonal] = useState([]);
	const [selectPerson, setSelectPerson] = useState({});
	const [tmpInputValue, setTmpInputValue] = useState({});
	const [errors, setErrors] = useState({});
	const [toogleMenu, setToogleMenu] = useState('');
	const [takeByLastPerson, setTakeByLastPerson] = useState(false);
	const [disabledNextBtn, setDisabledNextBtn] = useState(true);
	const [modal, setModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [audio] = useState(new Audio());
	const [disableBtn, setDisableBtn] = useState(false);
	const [disableSaveBtn, setDisableSaveBtn] = useState(false);
	const [unsavedChangesModalShowed, setUnsavedChangesModalShowed] = useState(
		false
	);
	const [toogleDisabled, setToogleDisabled] = useState(false);

	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;
	const history = useHistory();

	const handleForm = async (index) => {
		toogleMenu === index ? setToogleMenu('') : setToogleMenu(index);

		try {
			await accounts.getRecordingList(
				h11EditId && isH11Edit ? h11EditId : releaseId
			);

			const donePersonal = cloneDeep(personal);
			setInitialPersonal(donePersonal);
		} catch (error) {
			console.error('An error occurred:', error);
		}
	};

	const handleOnChange = (index) => (field) => (e) => {
		setErrors({});

		const selectedData = changeField({
			index,
			field,
			e,
			selectPerson,
			tmpInputValue,
		});

		selectedData.contributors.forEach((item) => {
			if (item.ownership && item.ownership.toString().length > 4) {
				if (item.publisher_share && +item.publisher_share > 0) {
					item.ownership = Number(item.ownership).toFixed(2);
				} else {
					item.ownership = Number(item.ownership).toFixed(1);
				}
			}
			if (item.publisher_share && item.publisher_share.toString().length > 5) {
				item.publisher_share = Number(item.publisher_share).toFixed(2);
			}
		});

		const data = selectedData.contributors.map((item) => {
			if (typeof item.ownership === 'string' && item.ownership.length > 0) {
				item.ownership = Number(item.ownership);
			}
			if (
				typeof item.publisher_share === 'string' &&
				item.publisher_share.length > 0
			) {
				item.publisher_share = Number(item.publisher_share);
			}
			if (item.publisher_share < 0) {
				item.publisher_share = 0.01;
			}
			return item;
		});

		personal.contributors = data;
		selectedData.contributors = data;
		personal[toogleMenu].contributors = selectedData.contributors;
		setSelectPerson({ ...selectedData });
		setPersonal([...personal]);
	};

	const handleOnFocus = (index) => (field) => (e) => {
		const value = e.target.value;
		setTmpInputValue({ index, field, value });
	};

	const handleOnBlur = (contributorsIndex) => (field) => (e) => {
		const value = e.target.value;
		if (value === '') {
			selectPerson.contributors[contributorsIndex][field] = tmpInputValue.value;
			personal.contributors = selectPerson.contributors;
			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
		}
		if (tmpInputValue.field === 'publisher_share' && value === '') {
			const contributors = selectPerson.contributors.filter(
				(item, index) => index !== contributorsIndex
			);

			const maxValue = contributors.reduce(
				(acc, item) => acc + item.ownership + +item.publisher_share,
				0
			);

			let data = 100 - maxValue - tmpInputValue.value;
			if (+data < 0) {
				data = 0.1;
			}

			selectPerson.contributors[contributorsIndex].ownership = +data.toFixed(2);
			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
			setTmpInputValue({});
		}
		if (tmpInputValue.field === 'ownership' && value === '') {
			const contributors = selectPerson.contributors.filter(
				(item, index) => index !== contributorsIndex
			);

			const maxValue = contributors.reduce(
				(acc, item) => acc + item.ownership + item.publisher_share,
				0
			);

			const data = 100 - maxValue - tmpInputValue.value;

			selectPerson.contributors[
				contributorsIndex
			].publisher_share = +data.toFixed(2);
			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
			setTmpInputValue({});
		}
	};

	const nextHandler = () => {
		const formattedPersonal = toGeneralFormat(personal);
		const formattedInitialPersonal = toGeneralFormat(initialPersonal);
		const isChanged =
			JSON.stringify(formattedPersonal) !==
			JSON.stringify(formattedInitialPersonal);

		if (isChanged && !unsavedChangesModalShowed) {
			setUnsavedChangesModalShowed(true);
			showModal(
				{
					text: <FormattedMessage id={'rod.modal.unsaved_changes_info.text'} />,
					withClose: true,
					confirmBtnTxt: <FormattedMessage id={'rod.modal.understood'} />,
				},
				ModalTypes.FULL_MODAL
			)();
		} else {
			setDisableBtn(true);
			if (typeof props.onChange === 'function') {
				audio.currentTime = 0;
				audio.pause();
				props
					.onChange(personal)
					.then()
					.catch()
					.finally(() => setDisableBtn(false));
			}
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/tracks`);
			} else {
				history.push(`/release/${releaseId}/tracks`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const toogleLastPerson = async (boolean) => {
		if (boolean) {
			setToogleDisabled(true);
			const lastPerson = cloneDeep(personal[toogleMenu - 1].contributors);

			const removingPropertyPerson = await Promise.all(
				lastPerson.map(async (person) => {
					const result = await accounts.contributorsInitialization({
						recording_id: selectPerson.id,
					});

					delete person.pivot;

					return {
						...person,
						id: result.data.data.id,
					};
				})
			);

			setSelectPerson({
				...selectPerson,
				contributors: removingPropertyPerson,
			});
		} else {
			setSelectPerson(personal[toogleMenu]);
		}
		setTakeByLastPerson(boolean);
		setToogleDisabled(false);
	};

	const isValid = (toCheck, showErr = false) => {
		let errorCount = 0;
		const newError = {};
		newError.title = [];
		newError.roles = [];
		newError.ownership = [];
		newError.publisher = [];
		newError.publisher_share = [];
		let sum = 0;

		toCheck &&
			toCheck.forEach((item, index) => {
				if (!item.title) {
					newError.title.push({ type: 'required', key: index });
					errorCount++;
				}

				if (item.title && item.title.length > 190) {
					newError.title.push({ type: 'max_length', key: index, value: 190 });
					errorCount++;
				}

				if (!item.roles?.length) {
					newError.roles.push(index);
					errorCount++;
				}

				if (item.roles && item.roles.length) {
					const requiredRoles = toCheck.map(
						(item) =>
							item.roles &&
							item.roles.filter((itemRole) => {
								switch (itemRole.logo ? itemRole.logo : itemRole) {
									case 'A':
										return itemRole;
									case 'C':
										return itemRole;
									case 'CA':
										return itemRole;
									default:
										break;
								}
								return false;
							})
					);
					if (!requiredRoles.flat().length) {
						newError.roles.push('required_roles');
						errorCount++;
					}
				}

				if (item.title && Number(item.ownership) === 0) {
					newError.ownership.push(index);
					errorCount++;
				}

				if (item.publisher_show && !item.publisher) {
					newError.publisher.push({ type: 'required', key: index });
					errorCount++;
				}

				if (item.publisher && item.publisher.length > 190) {
					newError.publisher.push({
						type: 'max_length',
						key: index,
						value: 190,
					});
					errorCount++;
				}

				if (item.publisher_show && Number(item.publisher_share) === 0) {
					newError.publisher_share.push(index);
					errorCount++;
				}

				if (item.publisher_share > 0) {
					sum += +item.publisher_share;
				}

				if (item.ownership > 0) {
					sum += +item.ownership;
				}
			});

		if (!errorCount && sum >= 99.94 && sum <= 100.06) {
			setErrors({});
			return false;
		} else {
			if (showErr) {
				if (sum <= 99.93 || sum >= 100.07) {
					newError.sum = '';
				}
				setErrors(newError);
			}
			return true;
		}
	};

	const recordingSave = (withModal = false, type = 'author', idx) => {
		if (isValid(selectPerson.contributors, true)) return;

		setDisableSaveBtn(true);
		setUnsavedChangesModalShowed(false);

		const clone = cloneDeep(selectPerson.contributors);

		clone.forEach((item) => {
			if (item.contributor_author_id) {
				if (item.contributor_author_id.artist_id) {
					item.contributor_author_id = item.contributor_author_id.artist_id;
				} else if (
					item.contributor_author_id.name &&
					!item.contributor_author_id.artist_id
				) {
					item.contributor_author_id = item.contributor_author_id.name;
				}
			}
		});

		clone.forEach((item) => {
			if (item.roles.length > 0) {
				item.roles = item.roles.map((role) => role.logo);
			}
		});

		setIsLoading(true);
		setDisableBtn(true);
		accounts
			.uploadRecordingCompositionInfo(
				h11EditId && isH11Edit ? h11EditId : releaseId,
				selectPerson.id,
				{
					contributors: clone,
				}
			)
			.then((res) => {
				if (withModal) {
					setModal(true);
					const { contributors } = selectPerson;
					selectPerson.done = true;
					selectPerson.contributors = res.data.data.map((item) => {
						const existItem = contributors?.find(
							(contributor) => contributor.title === item.title
						);
						if (existItem && existItem.publisher_show) {
							return {
								...item,
								publisher_show: true,
							};
						}
						return item;
					});

					setSelectPerson({
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find(
								(contributor) => contributor.title === item.title
							);

							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								};
							}
							return item;
						}),
					});

					const currentContributor = res.data.data[idx];
					setContributor({ ...currentContributor, type });
					setModal(true);
					setPersonal(personal);
					const savedPersonal = cloneDeep(personal);
					setInitialPersonal(savedPersonal);
				} else {
					const { contributors } = selectPerson;
					selectPerson.done = true;
					selectPerson.contributors = res.data.data;
					setSelectPerson({
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find(
								(contributor) => contributor.title === item.title
							);
							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								};
							}
							return item;
						}),
					});
					personal[toogleMenu] = {
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find(
								(contributor) => contributor.title === item.title
							);
							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								};
							}
							return item;
						}),
					};
					setPersonal(personal);
					const savedPersonal = cloneDeep(personal);
					setInitialPersonal(savedPersonal);

					if (personal[toogleMenu + 1]) {
						if (!isValid(personal[toogleMenu + 1].contributors)) {
							setToogleMenu('');
						} else {
							setToogleMenu(toogleMenu + 1);
						}
					} else {
						setToogleMenu('');
						setDisabledNextBtn(false);
					}
				}
			})
			.catch((error) => {
				console.error('Error', error);
			})
			.finally(() => {
				setIsLoading(false);
				setDisableSaveBtn(false);
				setDisableBtn(false);
			});
	};

	const recordingCmoOpen = (withModal = false, type = 'author', idx) => {
		setModal(true);
		selectPerson.done = true;
		const currentContributor = selectPerson?.contributors[idx];
		setContributor({ ...currentContributor, type });
		setModal(true);
		setPersonal(personal);
		const savedPersonal = cloneDeep(personal);
		setInitialPersonal(savedPersonal);
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const initializeContributors = async () => {
			setLoading(true);
			try {
				let res = await accounts.getRecordingList(
					h11EditId && isH11Edit ? h11EditId : releaseId
				);
				res = res.data.data;
				setPersonal(res);
				let first = true;
				let countValid = 0;

				for (let i = 0; i < res.length; i++) {
					// Check if contributors exist
					if (!res[i]?.contributors || !res[i]?.contributors?.length) {
						try {
							const result = await accounts.contributorsInitialization({
								recording_id: res[i].id,
							});
							res[i].contributors = [
								{
									id: result.data.data.id,
									title: '',
									society_id: null,
									roles: [],
									contributor_author_id: '',
									ownership: 100,
									publisher: '',
									publisher_share: 0,
									controlled_by_submitter: true,
								},
							];
						} catch (error) {
							console.error('An error occurred during initialization:', error);
						}
					} else {
						res[i].contributors.forEach((item, index) => {
							if (!item.contributor_author_id) {
								item.contributor_author_id = '';
							}

							if (
								item.contributor_author_id &&
								!item.contributor_author_id.name
							) {
								item.contributor_author_id = {
									name: item.contributor_author_id,
								};
							}

							if (
								item.id &&
								!item.ownership &&
								res[i].contributors.length === 1
							) {
								item.ownership = 100;
							}

							if (
								item.id &&
								!item.ownership &&
								res[i].contributors.length > 1
							) {
								item.ownership = index > 0 ? 0.1 : 100;
							}

							item.publisher_show = item.publisher ? true : false;
						});
					}

					// Check if contributors are valid
					if (!isValid(res[i].contributors)) {
						res[i].done = true;
						countValid++;
					} else if (first) {
						setToogleMenu(i);
						first = false;
					}

					if (countValid === res.length) {
						setToogleMenu('');
					}
				}

				setPersonal([...res]);
				const initialPersonal = cloneDeep([...res]);
				setInitialPersonal(initialPersonal);
			} catch (error) {
				console.error('Error fetching the recording list:', error);
			} finally {
				setLoading(false);
			}
		};

		// Call the async function
		initializeContributors();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTakeByLastPerson(false);
		if (toogleMenu === '') {
			return;
		}
		if (toogleMenu - 1 >= 0) {
			if (!isValid(personal[toogleMenu - 1].contributors)) {
				setLastSavePerson(personal[toogleMenu - 1]);
			} else {
				setLastSavePerson({});
			}
		} else {
			setLastSavePerson({});
		}

		setSelectPerson(personal[toogleMenu]);
		setErrors({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toogleMenu]);

	useEffect(() => {
		for (const item of personal) {
			if (!item.done || isValid(item.contributors, false)) {
				setDisabledNextBtn(true);
				break;
			} else {
				setDisabledNextBtn(false);
			}
		}
	}, [personal, selectPerson, disabledNextBtn]);

	return (
		<>
			<div className={s.page}>
				{loading ? (
					<Loading />
				) : (
					<div className={s.main__wrapper}>
						<SideTable
							toogleMenu={toogleMenu}
							personal={personal}
							handleForm={handleForm}
							disableSaveBtn={disableSaveBtn}
						/>

						{toogleMenu !== '' && (
							<AuthorForm
								lastSavePerson={lastSavePerson}
								errors={errors}
								handleOnChange={handleOnChange}
								handleOnFocus={handleOnFocus}
								handleOnBlur={handleOnBlur}
								tmpInputValue={tmpInputValue}
								handleForm={handleForm}
								toogleMenu={toogleMenu}
								selectPerson={selectPerson}
								setSelectPerson={setSelectPerson}
								recordingSave={recordingSave}
								takeByLastPerson={takeByLastPerson}
								setTakeByLastPerson={setTakeByLastPerson}
								toogleLastPerson={toogleLastPerson}
								toogleDisabled={toogleDisabled}
								modal={modal}
								setModal={setModal}
								onSave={(type, idx) => {
									recordingCmoOpen(true, type, idx);
								}}
								loading={loading}
								isLoading={isLoading}
								disableSaveBtn={disableSaveBtn}
								isH11Edit={isH11Edit}
							/>
						)}
					</div>
				)}
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn || disableBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
};

export default compose(withUI, withRoot)(Authors);
