/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 29/12/2020, 16:25
 */

import { Button } from 'components/Buttons';
import { Input } from 'components/Inputs';
import { Label } from 'components/Labels';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { showError } from 'validators/showError';
import styles from './ForgotPassword.module.css';
import { Form } from 'components/Form';
import { reachDataLayer, reachGoal } from 'utils';
import {
	EVENTS,
	pageUrl,
	paramName,
	paramSuccess,
	reachParams,
} from 'utils/reachParams';

class ForgotPassword extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
	};

	state = {
		errors: {},
	};

	form = React.createRef();
	email = React.createRef();
	newPassword = React.createRef();
	newPasswordRetype = React.createRef();

	handleOnPaste = (e) => {
		e.preventDefault();
	};

	handleValidate = () => {
		this.setState({ errors: {} });
		let errorCount = 0;
		if (
			this.newPassword.current.value !== this.newPasswordRetype.current.value
		) {
			this.setState((prev) => {
				return {
					errors: {
						...prev.errors,
						user: 'not_coincide',
					},
				};
			});
			errorCount++;
		}

		if (!this.email.current.value.length) {
			this.setState((prev) => {
				return {
					errors: {
						...prev.errors,
						email: 'required',
					},
				};
			});
			errorCount++;
		}

		if (!this.newPassword.current.value.length) {
			this.setState((prev) => {
				return {
					errors: {
						...prev.errors,
						password: 'required',
					},
				};
			});
			errorCount++;
		}

		const validateEmail = String(this.email.current.value)
			.toLowerCase()
			.match(/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{1,4}$/i);

		if (this.email.current.value.length && !validateEmail) {
			this.setState((prev) => {
				return {
					errors: {
						...prev.errors,
						email: 'invalid',
					},
				};
			});
			errorCount++;
		}
		if (!errorCount) {
			this.setState({ errors: {} });
			return true;
		} else {
			return false;
		}
	};

	submitHandler = (event) => {
		reachGoal(EVENTS.recoveryPasswordSave);
		event.preventDefault();

		const eventName = EVENTS.recoveryPasswordSave;
		const formName = 'восстановление пароля';

		const isValid = this.handleValidate();
		if (this.props.onSubmit && isValid) {
			this.props
				.onSubmit({
					email: this.email.current.value,
					password: this.newPassword.current.value,
				})
				.then(() => {
					const yaParams = {
						[pageUrl]: {
							[eventName]: {
								[formName]: {
									[paramName]: paramSuccess,
								},
							},
						},
					};
					reachParams(yaParams);
					reachDataLayer(eventName, formName, paramSuccess, '', '');
				})
				.catch((err) => {
					if (err?.message === 'not found' || err?.message === 'FORBIDDEN') {
						this.setState({ errors: { user: 'not_found' } });
					}
					if (err?.status === 'error') {
						this.setState({ errors: { user: 'wrong_data' } });
					} else {
						this.setState({ errors: err?.errors });
					}
					const yaParams = {
						[pageUrl]: {
							[eventName]: {
								[formName]: {
									[paramName]: err.message,
								},
							},
						},
					};
					reachParams(yaParams);
					reachDataLayer(eventName, formName, err.message, '', '');
				});
		}
	};

	render() {
		return (
			<Form
				className={styles.Form}
				ref={this.form}
				onSubmit={this.submitHandler}
			>
				<Label className={styles.Label} black>
					<FormattedHTMLMessage id={'rod.restore_password.header'} />
				</Label>
				<div className={styles.subTitle}>
					<FormattedMessage id={'rod.restore_password.subtitle'} />
				</div>
				<div className={styles.Fields}>
					<FormattedMessage id={'rod.field.email'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								ref={this.email}
								placeholder={placeholder}
								showError={showError('email')}
								errors={this.state.errors?.email || []}
							/>
						)}
					</FormattedMessage>

					<FormattedMessage id={'rod.field.new_password'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								errors={this.state.errors?.password || []}
								ref={this.newPassword}
								showError={showError('password')}
								placeholder={placeholder}
								tip={<FormattedHTMLMessage id={'rod.field.password.tip'} />}
								type="password"
							/>
						)}
					</FormattedMessage>

					<FormattedMessage id={'rod.field.new_password_again'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								ref={this.newPasswordRetype}
								placeholder={placeholder}
								onPaste={this.handleOnPaste}
								showError={showError('password')}
								tip={<FormattedHTMLMessage id={'rod.field.password.tip'} />}
								type="password"
								errors={this.state.errors?.password_confirmation || []}
								on
							/>
						)}
					</FormattedMessage>
				</div>
				<div className={styles.submit}>
					<Button
						className={styles.Button}
						text={<FormattedMessage id={'rod.action.save'} />}
						type={'submit'}
						variant={'primary'}
					/>
					{Object.keys(this.state.errors).includes('user') && (
						<span className={styles.Helper}>
							{
								<FormattedMessage
									id={`rod.error.forgot_password.${this.state.errors.user}`}
								/>
							}
						</span>
					)}
				</div>
			</Form>
		);
	}
}

export default ForgotPassword;
