// Core
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { reachDataLayer, reachGoal, reachParams } from 'utils';
import { accId, EVENTS, pageUrl, paramName, paramSuccess, userId } from 'utils/reachParams';

// UI
import ButtonAdd from './ButtonAdd/ButtonAdd';

// Icons
import releaseSvg from 'images/release_disc.svg';
import nota from 'images/nota_white.svg';
import dynamic from 'images/dynamic_white.svg';

// Styles
import s from '../MainPage.module.css';

const ButtonsGroup = ({
	setModal,
	createNewReleaseHandler,
	clickToCreateComposition,
}) => {
	const {
		upload,
		isCompositionCreateDisabled,
		isReleaseCreateDisabled,
		notTransferReleaseFlag,
		isExpiredContract,
	} = useContext(UIContext);

	const {
		user,
		accountId,
		isAddReleaseEnableForAccount,
		isAddCompositionEnableForAccount,
	} = useContext(AuthContext);

	const { pathname } = useLocation();

	const addReleaseHandler = () => {
		const eventName = EVENTS.addRelease;
		const formName = 'Кнопка "Добавить релиз"';

		reachGoal(EVENTS.addRelease);
		!notTransferReleaseFlag ? setModal(true) : createNewReleaseHandler();

		const yaParams = {
			[pageUrl]: {
				[eventName]: {
					[formName]: {
						[paramName]: paramSuccess,
						[userId]: user.id,
						[accId]: accountId,
					},
				},
			},
		};
		reachParams(yaParams);
		reachDataLayer(eventName, formName, paramSuccess, user.id, accountId);
	};

	return (
		<div className={s.wrapper__btn}>
			<div className={s.disabled__container}>
				{(isReleaseCreateDisabled && !isAddReleaseEnableForAccount) ||
				isExpiredContract ? (
					<ButtonAdd
						title={<FormattedMessage id={'rod.btn.add_release'} />}
						src={releaseSvg}
						className={s.btn__disabled}
					/>
				) : (
					<ButtonAdd
						title={<FormattedMessage id={'rod.btn.add_release'} />}
						src={releaseSvg}
						disabled={false}
						onClick={addReleaseHandler}
					/>
				)}
			</div>

			{(isCompositionCreateDisabled && !isAddCompositionEnableForAccount) ||
			isExpiredContract ? (
				<ButtonAdd
					disabled
					className={s.btn__disabled}
					title={<FormattedMessage id={'rod.btn.add_composition'} />}
					src={nota}
				/>
			) : (
				<Link
					to={{
						pathname: '/compositions/create',
						state: { url: pathname },
					}}
					className={s.link}
					onClick={clickToCreateComposition}
				>
					<ButtonAdd
						title={<FormattedMessage id={'rod.btn.add_composition'} />}
						src={nota}
					/>
				</Link>
			)}

			<ButtonAdd
				className={upload.recordings ? null : s.btn__disabled}
				disabled={!upload.recordings}
				title={<FormattedMessage id={'rod.btn.add_recordings'} />}
				src={dynamic}
			/>
		</div>
	);
};

export default ButtonsGroup;
