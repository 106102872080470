// Core
import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { format } from 'date-fns';

// Utils
import { setCache } from 'services/cacheHelper/setCache';
import { accounts, dictionaries } from 'services';
import { dateFormat } from 'logic';
import { showError } from 'validators/showError';
import { getCountries } from './utils/getCountries';
import { getReleaseTypes } from './utils/getReleaseTypes';
import {
	handleSetGRID,
	handleSetUPC,
	handleSetCatalogNumber,
} from './utils/handleSetIds';
import { getYearFromDate, getCreatedDateString } from 'utils';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { PerformerItem } from 'material-design/ReleaseCreate/PerformerItem';
import { ArtistForm } from 'material-design/ReleaseCreate/ArtistForm';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import { FormInput } from 'components/Form/FormInput';
import { BottomNavi, InfoHelper } from 'components';
import MainTable from './MainTable/MainTable';
import { SidepageModal } from 'material-design/modals';
import CatalogNumber from './CatalogNumber/CatalogNumber';
import { AutoWrapper } from 'components';

// Styles
import s from './Release.module.css';

const ReleaseInfo = (props) => {
	const accountId = localStorage.getItem('accountId');
	const { lang } = useContext(LangContext);
	const { isTransferRelease, setIsBottomNaviShow } = useContext(UIContext);

	const [personal, setPersonal] = useState({
		created_date: new Date().toISOString(),
		performers: [''],
	});
	const [selectedPerformer, setSelectedPerformer] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [errors, setErrors] = useState({});
	const [countries, setCountries] = useState([]);
	const [releaseTypes, setReleaseTypes] = useState([]);
	const [isReqLive] = useState(false);
	const [isShowPlineHelpBtn, setIsShowPlineHelpBtn] = useState(true);
	const [isShowClineHelpBtn, setIsShowClineHelpBtn] = useState(true);
	const [transferRelease, setTransferRelease] = useState(isTransferRelease);
	const [isH11Edit, setIsH11Edit] = useState(
		window.location.href.includes('/edit/')
	);
	const [countryId, setCountryId] = useState(null);
	const [disableBtn, setDisableBtn] = useState(false);
	const [isModalActive, setIsModalActive] = useState(false);

	const releaseId = localStorage.getItem('releaseId');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const transferRelease = localStorage.getItem('isTransferRelease');
		if (transferRelease) setTransferRelease(true);
	}, []);

	const changeMask = (e) => {
		setErrors({});
		personal.grid = e.target.value;
	};

	const checkIsValidMask = (e) => {
		if (!personal.generate_grid && personal.grid.includes('_')) {
			setErrors({ grid: [{ rule: 'wrong_format' }] });
		}
	};

	const handleOnBlurDateInput = (err, value) => {
		delete errors.created_date;
		props.checkDateErrors(err);

		if (err) {
			setErrors({ ...errors, created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const handleOnBlurPlineDateInput = (err, value) => {
		delete errors.date_p_line;
		props.checkDateErrors(err);

		if (err) {
			setErrors({ ...errors, date_p_line: [{ rule: 'wrong_format' }] });
		}
	};

	const handleOnBlurClineDateInput = (err, value) => {
		delete errors.date_c_line;
		props.checkDateErrors(err);

		if (err) {
			setErrors({ ...errors, date_c_line: [{ rule: 'wrong_format' }] });
		}
	};

	const changeField = (field) => (e) => {
		props.cleanErrorsField(field);
		switch (field) {
			case 'created_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = new Date(e);
					e = format(e, 'yyyy-MM-dd');
				} 
				personal[field] = e;
				break;
			case 'date_p_line':
			case 'date_c_line':
				if (e && e.getFullYear().toString().length === 4) {
					e = dateFormat(e);
				}
				personal[field] = e;
				break;
			case 'genres':
				const newArr = e.map((item) => (item.code ? item.code : item));
				personal[field] = newArr;
				break;
			case 'release_type_id':
				if (
					transferRelease &&
					personal.release_type_id === 70 &&
					personal.generate_ean
				) {
					personal.generate_ean = null;
				}
				personal[field] = e.id;
				break;
			case 'created_country_id':
				personal[field] = e;
				break;
			default:
				personal[field] = e.target.value;
				break;
		}
		setPersonal({ ...personal });
	};

	const handleFocus = (field) => (e) => {
		switch (field) {
			case 'p_line':
				setIsShowPlineHelpBtn(false);
				break;
			case 'c_line':
				setIsShowClineHelpBtn(false);
				break;

			default:
				break;
		}
	};

	const handleBlur = (field) => (e) => {
		switch (field) {
			case 'p_line':
				setIsShowPlineHelpBtn(true);
				break;
			case 'c_line':
				setIsShowClineHelpBtn(true);
				break;

			default:
				break;
		}
	};

	const nextHandler = () => {
		if (typeof props.onChange === 'function' && !Object.keys(errors).length) {
			setDisableBtn(true);
			const clone = cloneDeep(personal);
			if (clone.created_date) {
				const time = moment().format();
				clone.created_date = getCreatedDateString(time, clone.created_date);
			}

			if (clone.date_p_line && clone.date_p_line.includes('T')) {
				clone.date_p_line = getYearFromDate(clone.date_p_line);
			}

			if (clone.date_c_line && clone.date_c_line.includes('T')) {
				clone.date_c_line = getYearFromDate(clone.date_c_line);
			}

			if (clone.genres) {
				const genres = clone.genres.map((item) =>
					item.code ? item.code : item
				);
				clone.genres = genres;
			}

			if (clone.performers) {
				const performersArr = [...clone.performers];
				const formattedArr = performersArr.map((item) =>
					item.artist_id ? item.artist_id : item.name ? item.name : item
				);
				clone.performers = formattedArr.filter(
					(item) =>
						item !== '' && !(typeof item === 'object' && item.name === '')
				);
			}

			props
				.onChange(clone)
				.then()
				.catch()
				.finally(() => setDisableBtn(false));
		}
	};

	const disabledNextBtn =
		!personal.title ||
		!personal.release_type_id ||
		!personal.genres ||
		!personal.genres.length ||
		!personal.performers.length ||
		personal.performers.every((item) => item === '') ||
		!personal.p_line ||
		!personal.c_line ||
		!personal.date_p_line ||
		!personal.date_c_line ||
		!personal.created_date ||
		(!isH11Edit && !personal.generate_ean && !personal.ean) ||
		Object.keys(errors).includes('created_date') ||
		Object.keys(errors).includes('date_p_line') ||
		Object.keys(errors).includes('date_c_line') ||
		(!transferRelease &&
			!isH11Edit &&
			!personal.generate_catalog_number &&
			!personal.catalog_number);

	const getClassnameForInfoLineBtn = (param) => {
		switch (param) {
			case 'c_line':
				return isShowClineHelpBtn && !personal[param] ? s.info : s.infoActive;
			default:
				return isShowPlineHelpBtn && !personal[param] ? s.info : s.infoActive;
		}
	};

	const handleChangeArtist = (data, index) => {
		let updatedPerformers = [...personal.performers];
		updatedPerformers[index] = data;

		if (updatedPerformers[updatedPerformers.length - 1] !== '') {
			updatedPerformers.push('');
		}

		updatedPerformers = updatedPerformers.filter(
			(item, i) => item !== '' || i === updatedPerformers.length - 1
		);

		const updatedPersonal = {
			...personal,
			performers: updatedPerformers,
		};

		setPersonal(updatedPersonal);
	};

	const handleAddArtist = () => {
		const updatedPerformers = [...personal.performers, ''];
		const updatedPersonal = {
			...personal,
			performers: updatedPerformers,
		};
		setPersonal(updatedPersonal);
	};

	const handleDeleteArtist = (index) => {
		const updatedPerformers = personal.performers.filter((_, i) => i !== index);
		const updatedPersonal = {
			...personal,
			performers: !updatedPerformers.length ? [''] : updatedPerformers,
		};
		setPersonal(updatedPersonal);
	};

	const handleSavePerformer = (updatedPerformer, index) => {
		const updatedPerformers = [...personal.performers];
		updatedPerformers[index] = updatedPerformer;
		if (updatedPerformers[updatedPerformers.length - 1] !== '') {
			updatedPerformers.push('');
		}
		setPersonal({
			...personal,
			performers: updatedPerformers,
		});
	};

	const handleOpenModal = (performer, index, text) => {
		setSelectedPerformer(!performer.name ? { name: text } : performer);
		setSelectedIndex(index);
		setIsModalActive(true);
	};

	useEffect(() => {
		accounts
			.getAccountContactsData(accountId)
			.then((res) => {
				res = res.data.data;
				if (res.country_id) {
					setCountryId(res.country_id);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getCountries(isReqLive, setCountries, dictionaries, setCache);

		if (
			!releaseId &&
			accountId &&
			personal &&
			!personal.created_country_id &&
			countryId
		) {
			setPersonal({ ...personal, created_country_id: countryId });
		}

		if (releaseId && !isH11Edit) {
			accounts
				.getReleaseData(releaseId)
				.then((res) => {
					if (res.data.data.grid) {
						res.data.data.grid.replace(
							/(\d{2})\.(\d{5})\.(\d{10})\.(\d{1})/,
							'$1-$2-$3-$4'
						);
					}
					res.data.data['created_date'] = res.data.data['created_date']?.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];

					if (
						typeof res.data.data.performers === 'string' ||
						typeof res.data.data.performers === 'number'
					) {
						res.data.data.performers = [
							{ name: res.data.data.performers.toString() },
							'',
						];
					} else {
						const formattedPerformers = res.data.data.performers.map((item) => {
							if (typeof item === 'string' || typeof item === 'number') {
								return { name: item.toString() };
							}
							return item;
						});
						res.data.data.performers = [...formattedPerformers, ''];
					}

					const step = res.data.data?.step;
					setIsH11Edit(step.includes('h11'));
					if (step.includes('h11')) {
						const releaseEdit = { id: h11EditId, step: 'release' };
						localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					}
					setPersonal(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		if (isH11Edit) {
			accounts
				.getReleaseData(h11EditId)
				.then((res) => {
					if (res.data.data.grid) {
						res.data.data.grid.replace(
							/(\d{2})\.(\d{5})\.(\d{10})\.(\d{1})/,
							'$1-$2-$3-$4'
						);
					}
					res.data.data['created_date'] = res.data.data['created_date']?.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];

					if (typeof res.data.data.performers === 'string') {
						res.data.data.performers = [{ name: res.data.data.performers }, ''];
					} else {
						const formattedPerformers = res.data.data.performers.map((item) => {
							if (typeof item === 'string') {
								return { name: item };
							}
							return item;
						});
						res.data.data.performers = [...formattedPerformers, ''];
					}

					res.data.data.created_country_id = countryId;
					const step = res.data.data?.step;
					setIsH11Edit(step.includes('h11'));
					if (step.includes('h11')) {
						const releaseEdit = { id: h11EditId, step: 'release' };
						localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					}
					setPersonal(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		// eslint-disable-next-line
	}, [countryId]);

	useEffect(() => {
		getReleaseTypes(
			personal,
			isReqLive,
			lang,
			setReleaseTypes,
			accounts,
			setCache
		);
		// eslint-disable-next-line
	}, [lang, personal]);

	return (
		<>
			<div className={s.page}>
				<div className={s.main}>
					<span className={s.title}>
						<FormattedMessage id={'rod.add_composition_page.step1'} />
					</span>
					<MainTable
						changeField={changeField}
						errors={errors}
						errorsFromBack={props.errors}
						personal={personal}
						handleOnBlurDateInput={handleOnBlurDateInput}
						handleOnBlurPlineDateInput={handleOnBlurPlineDateInput}
						handleOnBlurClineDateInput={handleOnBlurClineDateInput}
						handleFocus={handleFocus}
						handleBlur={handleBlur}
						countries={countries}
						getClassnameForInfoLineBtn={getClassnameForInfoLineBtn}
						releaseTypes={releaseTypes}
						isH11Edit={isH11Edit}
					/>
					<span className={`${s.title} ${s.title__artists}`}>
						<FormattedMessage
							id={'rod.release.create.step.release.label.artists'}
						/>
					</span>
					<div className={s.artistsBlock}>
						{personal.performers &&
							personal.performers.length > 0 &&
							personal.performers.map((performer, index) => (
								<PerformerItem
									performer={performer}
									index={index}
									lastIndex={personal.performers.length - 1}
									personal={personal}
									handleChangeArtist={handleChangeArtist}
									errors={props.errors}
									cleanErrorsField={props.cleanErrorsField}
									handleOpenModal={handleOpenModal}
									handleAddArtist={handleAddArtist}
									handleDeleteArtist={handleDeleteArtist}
									dataField="performers"
									isH11Edit={isH11Edit}
								/>
							))}
					</div>
					<span className={`${s.title} ${s.title__id}`}>
						<FormattedMessage
							id={'rod.release.create.step.release.label.identifiers'}
						/>
					</span>
					<div className={s.flex}>
						<div className={s.item}>
							<div
								className={
									personal.generate_ean || personal?.step?.includes('h11')
										? `${s.input} ${s.disabled}`
										: s.input
								}
							>
								<FormInput
									type="muiInput"
									name={'ean'}
									onChange={changeField}
									errors={props.errors}
									data={personal}
									label={'UPC'}
									disabled={
										personal.generate_ean || personal.step?.includes('h11')
									}
									required={!personal?.step?.includes('h11')}
								/>
								{Object.keys(props.errors).includes('ean') && (
									<span className={s.Helper}>
										{showError(props.errors.ean[0].rule)(props.errors.ean[0])}
									</span>
								)}
							</div>

							{(!transferRelease ||
								(transferRelease && personal.release_type_id === 70)) &&
								!personal?.step?.includes('h11') && (
									<AutoWrapper
										onClick={() =>
											handleSetUPC(
												personal?.generate_ean,
												personal,
												setPersonal
											)
										}
										check={personal?.generate_ean}
									/>
								)}
						</div>

						<div className={s.item}>
							<div
								className={
									personal.generate_grid || personal?.step?.includes('h11')
										? `${s.input} ${s.disabled}`
										: s.input
								}
							>
								<InputMask
									mask={'**-*****-**********-*'}
									name={'grid'}
									onChange={changeMask}
									errors={errors}
									value={personal.grid ? personal.grid : ''}
									onBlur={checkIsValidMask}
									disabled={
										personal.generate_grid || personal?.step?.includes('h11')
									}
								>
									{() => (
										<TextFieldInput
											variant="standard"
											type="muiInput"
											name={'grid'}
											error={Object.keys(errors).length > 0 && errors.grid}
											value={personal.grid ? personal.grid : ''}
											label={'GRID'}
											disabled={
												personal.generate_grid ||
												personal?.step?.includes('h11')
											}
										/>
									)}
								</InputMask>
								{Object.keys(errors).includes('grid') && (
									<span className={s.Helper}>
										{showError(errors.grid[0].rule)(errors.grid[0])}
									</span>
								)}
							</div>

							{!transferRelease && !personal?.step?.includes('h11') && (
								<AutoWrapper
									onClick={() => {
										setErrors({});
										handleSetGRID(
											personal?.generate_grid,
											personal,
											setPersonal
										);
									}}
									check={personal?.generate_grid}
								/>
							)}
						</div>
						<CatalogNumber
							personal={personal}
							setPersonal={setPersonal}
							changeField={changeField}
							transferRelease={transferRelease}
							handleSetCatalogNumber={handleSetCatalogNumber}
							errors={props.errors}
						/>
					</div>
				</div>
				<InfoHelper text="rod.release.create.step.release.description" />
				<SidepageModal
					customWidth="434px"
					headerBottom="12px"
					active={isModalActive}
					setActive={() => setIsModalActive(false)}
				>
					<ArtistForm
						isModalActive={isModalActive}
						data={selectedPerformer}
						dataField="performers"
						index={selectedIndex}
						setIsModalActive={setIsModalActive}
						onSave={handleSavePerformer}
					/>
				</SidepageModal>
			</div>
			<BottomNavi
				showPrevBtn={false}
				disabled={
					(!isH11Edit && disabledNextBtn) ||
					!personal.created_date ||
					disableBtn
				}
				next={nextHandler}
			/>
		</>
	);
};

export default ReleaseInfo;
